import React, { memo, useState } from 'react'

import InfoModal from "./InfoModal"
import { Button} from "antd"
import arrow from "../images/arrow.png"

const OdooUpgradeSimplified = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div>
            <div className="OdooUpgradeSimplified">
                <h1>Simplified Steps to get you Started</h1>
                <div className="div-container">
                    <div class="div1">
                        <h2>01</h2>
                        <p className="underlined-paragraph">
                            <strong>Assessment and Planning</strong>
                        </p>
                        <ul>
                            <li>
                                Assess your current Dynamics AX 2012 setup, noting customizations and third-party solutions.
                            </li>
                            <li>Develop a detailed project plan, highlighting key milestones and resource requirements.</li>
                        </ul>
                        <Button className="button-our" onClick={showModal}>
                            Let’s Discuss
                            <img src={arrow} width="20px" style={{ marginLeft: "4px" }} alt='Discuss arrow'/>
                        </Button>
                    </div>
                    <div class="div2">
                        <h2>02</h2>
                        <p className="underlined-paragraph">
                            <strong>Data Preparations</strong>
                        </p>
                        <ul>
                            <li>
                                Clean and optimize your data for accuracy.
                            </li>
                            <li>
                                Back up crucial data to avoid any loss during the upgrade.
                            </li>
                            <li>Address any differences in data structure between the two versions.</li>
                        </ul>
                    </div>
                    <div class="div3">
                        <h2>03</h2>
                        <p className="underlined-paragraph">
                            <strong>Code and Object Upgrade</strong>
                        </p>
                        <ul>
                            <li>
                                Upgrade customizations and code to align with Dynamics 365 standards.
                            </li>
                            <li>
                                Test and modify scripts for compatibility.
                            </li>
                        </ul>
                    </div>
                    <div class="div4">
                        <h2>04</h2>
                        <p className="underlined-paragraph">
                            <strong>Thorough Testing</strong>
                        </p>
                        <ul>
                            <li>
                                Rigorously test the upgraded system for any glitches.
                            </li>
                            <li>
                                Check integrations to ensure smooth functionality.
                            </li>
                            <li>Run user acceptance tests (UAT) to confirm the system meets business requirements.</li>
                        </ul>
                    </div>
                    <div class="div5">
                        <h2>05</h2>
                        <p className="underlined-paragraph">
                            <strong>User Training</strong>
                        </p>
                        <ul>
                            <li>
                                Train end-users on Dynamics 365's new features and interface.
                            </li>
                            <li>
                                Provide clear documentation and resources for ongoing support.
                            </li>
                        </ul>
                    </div>
                    <div class="div6">
                        <h2>06</h2>
                        <p className="underlined-paragraph">
                            <strong>Go-Live and Monitoring</strong>
                        </p>
                        <ul>
                            <li>
                                Execute the upgrade in a controlled environment.
                            </li>
                            <li>
                                Monitor system performance post-upgrade, addressing any unforeseen issues.
                            </li>
                            <li>Have a contingency plan ready for critical situations.</li>
                        </ul>
                    </div>
                    <div class="div7">
                        <h2>07</h2>
                        <p className="underlined-paragraph">
                            <strong>Post-Upgrade Support</strong>
                        </p>
                        <ul>
                            <li>
                                Offer continued support to users adapting to the changes.
                            </li>
                            <li> Quickly address any post-upgrade issues.</li>
                            <li>Optimize system performance based on feedback and monitoring.</li>
                        </ul>
                    </div>
                    <div class="div8">
                        <h2>08</h2>
                        <p className="underlined-paragraph">
                            <strong>Continuous Improvement:</strong>
                        </p>
                        <ul>
                            <li>
                                Gather feedback to identify areas for improvement.
                            </li>
                            <li>Plan for future updates and enhancements based on user experiences.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <InfoModal
                companyIsEnabled
                url="http://185.213.27.237:8066/Email/ContactUs"
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}

export default memo(OdooUpgradeSimplified)
